/* Quill Editor */
.ql-container.ql-snow {
  border: none !important;
  font-size: 18px;
}

.ql-container {
  font-family: "Open Sans", sans-serif !important;
}

.ql-editor {
  padding: 5px !important;
}

/* Ant Design Overrides */
.ant-form-item-label > label {
  color: #475569;
  font-weight: 500;
  font-size: 14px;
}

.ant-input,
.ant-input-number,
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 6px;
  border-color: #e2e8f0;
  padding: 6px 12px;
  height: 36px;
}

.ant-input-number-input {
  height: 34px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 12px;
}

.ant-input:hover,
.ant-input:focus,
.ant-input-number:hover,
.ant-input-number:focus,
.ant-select:not(.ant-select-customize-input) .ant-select-selector:hover {
  border-color: #94a3b8;
}

.ant-input-textarea {
  padding: 0;
}

.ant-form-item {
  margin-bottom: 20px;
}